import React from "react";
import Layout from "@/components/Layout";
import { graphql } from "gatsby";
import { CustomRenderer } from "@/utils/CustomRenderer";

export default function Policy({ data }: any) {
  return (
    <Layout title="Privacy Policy" ContactUsBtn={false}>
      <div className="dark:text-white px-[20px] lg:px-0 text-gray lg:container max-w-[800px] space-y-6 my-[4rem]">
        <CustomRenderer document={data.cms.pages[0].content.document} />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query PolicyPageQuery {
    cms {
      pages(where: { title: { contains: "Privacy" } }) {
        content {
          document(hydrateRelationships: true)
        }
      }
    }
  }
`;
